import React from "react";

const Terms = () => {
  return (
    <div className="container mx-auto pt-[60px] pb-[80px]">
      <div className="px-10 py-8 pt-8 bg-white rounded-md">
        <div className="text-[35px]">Total: from ₹ 45</div>
        <button class="bg-[#38B6FF] hover:bg-blue-700 mt-5 text-white font-bold py-4 px-20 rounded">
          Submit Order
        </button>
        <div className="text-[20px] mt-7">
          By Clicking 'Submit order' you are forwarding your request to couriers
          and agree to Our Terms and Conditions along with the clauses of the
          agreements
        </div>
        <div className="text-[25px] mt-7">
          SMS notifications can be set after sending an order
        </div>
        <div className="text-[25px] mt-7">
          To order a delivery boy just follow these simple steps:
        </div>
        <div className="px-9 mt-6 text-[20px]">
          <div className="mt-5">
            1. Provide us with necessary details: addresses, phone numbers at
            each address, desired time of delivery, weight of the delivery
          </div>
          <div className="mt-5">
            2. If you like our quote, then click 'Submit order' button
          </div>
          <div className="mt-5">
            3. Receive a call from the delivery boy assigned for your order.
            Negotiate, at which point he will be paid. Give him more details
            about your parcel and desired process.
          </div>
          <div className="mt-5">
            4. Send us a message or call our Operator in case of any doubt. You
            will get the Operator's number by clicking 'Order' button, save it
            with your order's number
          </div>
          <div className="mt-5">
            5.Get your delivery performed. Give the Courier signature right on
            his smartphone's screen to verify, that all have been done properly
          </div>
          <div className="mt-5">
            - You may rate a courier after the delivery is done to help us
            assign just best couriers.
          </div>
        </div>
        <div className="text-[25px] mt-7">Best regards, 3Parcel team</div>
      </div>
    </div>
  );
};

export default Terms;
