import React from "react";

const Weight = () => {
  return (
    <div className="container mx-auto ">
      <div className="px-1 py-1 pt-1 ">
        <div className="text-[35px]">Weight</div>
        <div className="grid  grid-cols-2 lg:grid-cols-5 gap-[20px] mt-[50px] justify-items-center  px-[8%]">
          <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-[20px] focus:border-blue-500 focus:outline-none ">
            Up to 5 kg
          </button>
          <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-[20px] focus:border-blue-500 focus:outline-none ">
            Up to 10 kg
          </button>
          <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-[20px] focus:border-blue-500 focus:outline-none">
            Up to 20 kg
          </button>
          <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-[20px] focus:border-blue-500 focus:outline-none ">
            Up to 30 kg
          </button>
          <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-[20px] focus:border-blue-500 focus:outline-none ">
            Up to 40 kg
          </button>
        </div>
      </div>
    </div>
  );
};

export default Weight;
