import React from "react";

const Footer = () => {
  return (
    <div className="bg-[#ffffff] ">
      <div className="container mx-auto pt-[50px] pb-[70px]">
        <div className=" lg:flex  sm:flex-row gap-[30px]">
          <div className="basis-[30%] text-blue-600 md:text-4xl text-center md:text-left  ">
            <img
              className="max-w-screen-lg mx-auto lg:ml-[0.01rem]"
              src="./assets/Logo.png"
              alt=""
            />

            <div className="space-y-[15px] mt-6 font-semibold cursor-pointer">
              <p className="text-[20px] leading-[19px]  py-2">Mumbai</p>
              <p className="text-[20px] leading-[19px]  py-2">Delhi/NCR</p>
              <p className="text-[20px] leading-[19px]  py-2">Bengaluru</p>
              <p className="text-[20px] leading-[19px]  py-2">Hyderabad</p>
              <p className="text-[20px] leading-[19px]  py-2">Ahmedabad</p>
              <p className="text-[20px] leading-[19px]  py-2">Chennai</p>
              <p className="text-[20px] leading-[19px]  py-2">Kolkata</p>
              <p className="text-[20px] leading-[19px]  py-2">Pune</p>
            </div>
            {/* <div className="flex items-center gap-[15px] md:text-4xl text-center md:text-left">
              <div className="h-[30px] w-[30px] border rounded-full cursor-pointer"></div>
              <div className="h-[30px] w-[30px] border rounded-full cursor-pointer"></div>
              <div className="h-[30px] w-[30px] border rounded-full cursor-pointer"></div>
            </div> */}
          </div>
          <div className="basis-[70%] grid grid-cols-1 lg:grid-cols-3 md:text-4xl text-center md:text-left gap-[30px] text-black font-semibold">
            <div className="space-y-[15px] cursor-pointer  ">
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Become a courier
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Cookie Statement
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Privacy Policy
              </p>

              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                History
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700 ">
                Disclaimers & Dispute Resolution
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Refund and Cancellation
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Community Guidelines
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                3parcel Blog
              </p>
            </div>

            <div className="space-y-[15px] cursor-pointer ">
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                For Businesses
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                API Integration
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                FAQs
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Contacts
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Track Order
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Courier Service in Mumbai
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Pricing
              </p>
              <p className="text-[20px] leading-[19px] font-normal py-2 hover:text-blue-700">
                Terms and conditions
              </p>
            </div>
            <div className="space-y-[15px]  mx-auto mt-10  justify-center">
              <p className=" ">
                <img src="./assets/playstore.svg" alt="" />
              </p>
              <p className="">
                <img src="./assets/appstore.svg" alt="" />
              </p>
              <p className="">
                <img src="./assets/appgallery.svg" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex items-center justify-center py-[15px] text-black">
        <p className="text-[20px] leading-[19px] font-semibold ">
          © Copyright . All Rights Reserved Designed by anywhere
        </p>
      </div>
    </div>
  );
};

export default Footer;
