import { useState } from "react";

const Navbar = () => {
  let [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="shadow-md w-full  top-0 left-0 ">
        <div className="container m-auto">
          <div className="md:flex items-center justify-between bg-white py-4 md:px-10 px-7">
            <div
              className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
      text-gray-800"
            >
              <span className="text-3xl text-indigo-600 mr-1 pt-2">
                <img
                  className="h-[50px] w-[60px]"
                  src="./assets/Logo.png"
                  alt=""
                />
              </span>
              3parcel
            </div>

            <div
              onClick={() => setOpen(!open)}
              className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
            >
              <ion-icon name={open ? "close" : "menu"}></ion-icon>
            </div>

            <ul
              className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static lg:bg-white  md:z-auto  left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
                open ? "top-20 " : "top-[-490px]"
              }`}
            >
              <li className="md:ml-8 text-xl md:my-0 my-7">
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-700 duration-500"
                >
                  Become a courier
                </a>
              </li>
              <li className="md:ml-8 text-xl md:my-0 my-7">
                <a
                  href="#"
                  className="text-gray-800 hover:text-blue-700 duration-500"
                >
                  Contact sales
                </a>
              </li>
              <li className="md:ml-8 text-xl md:my-0 my-7">
                <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                  Login / Signup
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
