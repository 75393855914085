import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

const Delivery = () => {
  const [val, setVal] = useState([]);
  const handleAdd = () => {
    const abc = [...val, []];
    setVal(abc);
  };
  const handleChange = () => {};

  const [show, setShow] = useState(true);
  const [showtab, setShowtab] = useState(true);
  const [showtab1, setShowtab1] = useState(1);

  return (
    <div className="container mx-auto pt-[20px] pb-[20px]">
      <div>
        <div className=" font-semibold text-[25px]">Pick Location</div>
        <div className="px-4 py-4 pt-4 bg-white rounded-md">
          <input
            type="text"
            id="first_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[40px] "
            placeholder="Name"
            required
          />
          <input
            type="text"
            id="first_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[50px]  text-[26px]"
            placeholder="Name"
            required
          />
          <textarea
            id="message"
            rows="4"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[40px]  text-[26px]"
            placeholder="Address "
          ></textarea>
          {!show && (
            <div className="grid  grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
              <input
                type="text"
                className="w-3/3 p-2 border rounded"
                placeholder="Input 1"
              />
              <input
                type="text"
                className="w-3/3 p-2 border rounded"
                placeholder="Input 2"
              />

              <select
                id="dropdown-input"
                name="dropdown-input"
                className="w-3/3 p-2 border rounded"
              >
                <option value="" disabled selected>
                  Select an option
                </option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
              </select>
            </div>
          )}
          <button
            className="text-[#0d42ff] text-[20px] mt-4"
            onClick={() => setShow(!show)}
          >
            {show === true
              ? "Additional Services (Cash on Delivery, Buy from Store)"
              : "Do not need additional services"}
          </button>
        </div>
      </div>
      <div className="pt-[20px] pb-[20px]">
        <div className=" font-semibold text-[25px]">Pick Location</div>
        <div className="px-4 py-4 pt-4 bg-white rounded-md">
          <input
            type="text"
            id="first_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[40px] text-[26px]"
            placeholder="Name"
            required
          />
          <input
            type="text"
            id="first_name"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[50px]  text-[26px]"
            placeholder="Name"
            required
          />
          <textarea
            id="message"
            rows="4"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[40px]  text-[26px]"
            placeholder="Address "
          ></textarea>

          {!showtab && (
            <div className="grid  grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
              <input
                type="text"
                className="w-3/3 p-2 border rounded"
                placeholder="Input 1"
              />
              <input
                type="text"
                className="w-3/3 p-2 border rounded"
                placeholder="Input 2"
              />

              <select
                id="dropdown-input"
                name="dropdown-input"
                className="w-3/3 p-2 border rounded"
              >
                <option value="" disabled selected>
                  Select an option
                </option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
              </select>
            </div>
          )}

          <button
            className="text-[#0d42ff] text-[20px] mt-4"
            onClick={() => setShowtab(!showtab)}
          >
            {showtab === true
              ? "Additional Services (Cash on Delivery, Buy from Store)"
              : "Do not need additional services"}
          </button>
        </div>
      </div>

      <div className="pt-[20px] pb-[20px]">
        {val.map((data, i) => {
          return (
            <>
              <div className=" font-semibold text-[25px]">Pick Location</div>
              <div className="px-4 py-4 pt-4 bg-white rounded-md">
                <input
                  type="text"
                  id="first_name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[40px]  text-[26px]"
                  placeholder="Name"
                  required
                />

                <input
                  type="text"
                  id="first_name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[50px]  text-[26px]"
                  placeholder="Name"
                  required
                />

                <textarea
                  id="message"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-[40px]  text-[26px]"
                  placeholder="Address "
                ></textarea>

                {!showtab1 && (
                  <div className="grid  grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
                    <input
                      type="text"
                      className="w-3/3 p-2 border rounded"
                      placeholder="Input 1"
                    />
                    <input
                      type="text"
                      className="w-3/3 p-2 border rounded"
                      placeholder="Input 2"
                    />

                    <select
                      id="dropdown-input"
                      name="dropdown-input"
                      className="w-3/3 p-2 border rounded"
                    >
                      <option value="" disabled selected>
                        Select an option
                      </option>
                      <option value="option1">Option 1</option>
                      <option value="option2">Option 2</option>
                    </select>
                  </div>
                )}

                <button
                  className="text-[#0d42ff] text-[20px] mt-4"
                  onClick={() => setShowtab1(!showtab1)}
                >
                  {showtab1 === true
                    ? "Additional Services (Cash on Delivery, Buy from Store)"
                    : "Do not need additional services"}
                </button>
              </div>
            </>
          );
        })}
      </div>
      <button
        type="button"
        className="text-white bg-[#38B6FF] hover:bg-[#38B6FF]/90 focus:ring-4 focus:outline-none focus:ring-[#38B6FF]/50 font-medium rounded-lg text-sm px-20 py-2.5 text-center inline-flex items-center dark:focus:ring-[#38B6FF]/55 mr-2 mb-2 text-[25px] leading-[24px]  "
        onClick={() => handleAdd()}
      >
        <AiOutlinePlus /> Add delivery address
      </button>
    </div>
  );
};

export default Delivery;
