import React from "react";

const Order = () => {
  return (
    <div className="container mx-auto pt-[80px] pb-[80px]">
      <div className="text-[70px]">Order</div>
      <div className="px-10 py-8  bg-white rounded-md">
        <div className="grid  grid-cols-1 lg:grid-cols-2 gap-[20px] justify-items-center  px-[8%]">
          <button className="border   pt-[40px] pl-[35px] rounded-md bg-white  shadow-lg focus:border-blue-500 focus:outline-none">
            <p className="text-[#0e1d34] text-[25px] leading-[24px] font-semibold space-y-[20px]">
              Deliver Now
            </p>
            <ul className="space-y-[10px]">
              <li className="text-[#6c757d] text-[16px] leading-[20px] font-normal flex items-center gap-[10px] mt-[20px]">
                {" "}
                We will assign the nearest <br /> courier to pick-up <br /> and
                deliver as soon as possible.
              </li>

              <h2 className="text-[rgba(108, 117, 125, 0.8)] text-[18px] leading-[22px] font-normal mt-[20px] pb-[40px]">
                <span className=" text-[35px] leading-[58px] font-normal">
                  from Rs.45
                </span>
              </h2>
            </ul>
          </button>
          <button className="border   pt-[40px] pl-[35px] rounded-md bg-white  shadow-lg focus:border-blue-500 focus:outline-none">
            <p className="text-[#0e1d34] text-[25px] leading-[24px] font-semibold space-y-[20px] ">
              Schedule
            </p>
            <ul className="space-y-[10px]">
              <li className="text-[#6c757d] text-[16px] leading-[20px] font-normal flex items-center gap-[10px] mt-[20px]">
                {" "}
                We will assign the nearest <br /> courier to pick-up <br /> and
                deliver as soon as possible.
              </li>

              <h2 className="text-[rgba(108, 117, 125, 0.8)] text-[18px] leading-[22px] font-normal mt-[20px] pb-[40px]">
                <span className=" text-[35px] leading-[58px] font-normal">
                  from Rs.45
                </span>
              </h2>
            </ul>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Order;
