import React from "react";
import Delivery from "../components/Delivery";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Order from "../components/Order";
import Payment from "../components/Payment";
import Terms from "../components/Terms";
import Weight from "../components/Weight";

const Routing = () => {
  return (
    <>
      <Navbar />
      <div className="bg-gray-100">
        <Order />
        <Weight />
        <Delivery />
        <Payment />
        <Terms />
      </div>
      <Footer />
    </>
  );
};

export default Routing;
