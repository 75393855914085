import "./App.css";
import Routing from "./router/route";

function App() {
  return (
    <div>
      <Routing />
    </div>
  );
}
export default App;
