import React from "react";

const Payment = () => {
  return (
    <div className="container mx-auto pt-[80px] pb-[80px] ">
      <div className="px-8 py-10 pt-2 rounded-md">
        <h1 className="text-[30px]">Payment Type</h1>
        <div className="grid  grid-cols-1 lg:grid-cols-2 gap-[20px] mt-[50px] justify-items-center  px-[8%]">
          <button className=" flex gap-5 border w-[80%] h-30  pt-[30px] pl-[35px] rounded-lg bg-white focus:border-blue-500 focus:outline-none">
            <img className="w-[70px] h-[20px] " src="./assets/upi.png" alt="" />
            <div>
              <p className="text-[#0e1d34] text-[25px] leading-[24px] font-semibold  ">
                Online
              </p>
              <p className="text-[#0e1d34] text-[17px] leading-[24px] ">
                Online
              </p>
            </div>
          </button>

          <button className=" flex gap-5 border w-[80%] h-30  pt-[30px] pl-[35px] rounded-lg bg-white focus:border-blue-500 focus:outline-none">
            <img
              className="w-[50px] h-[30px] "
              src="./assets/Vector.png"
              alt=""
            />
            <div>
              <p className="text-[#0e1d34] text-[25px] leading-[24px] font-semibold ">
                Case
              </p>
              <p className="text-[#0e1d34] text-[17px] leading-[24px] ">Case</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
